/* styles.css */
.body {
   font-family: Arial, sans-serif;
   margin: 0;
   padding: 0;
   background-color: #ffffff;
}

.header {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   gap: 14px;
   background-color: #2c2f32;
   color: #ffffff;
   padding: 20px;
   text-align: center;
 }
 
.nav {
   display: flex;
   gap: 10px;
   align-items: center;
}

.header_p {
   text-decoration: none;
   font-size: large;
   font-weight: bold;
}

.nav_button {
   color: #ffffff;
   text-decoration: none;
   padding: 10px;
   border-radius: 5px;
   background-color: #d128cc;
   font-size: large;
   font-weight: bold;
}

   .nav_button:hover {
      background-color: #0cc430;
   }

.search {
   display: flex;
   font-size: 30px;
   max-height: 32px;
}

.main {
   padding: 20px;
}

.inventoryContainer {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 14px;
   padding-bottom: 50px;
}

.itemHeader {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   background-color: #2c2f32;
   color: #ffffff;
   padding: 6px;
   border: solid 2px #000000;

}

.clickMe {
   max-height: 40px;
   margin-right: 10px;
   color: rgb(255, 255, 255);
   text-align: right;
   right: 0px;
   font-weight: 600;
   font-size: 16px;
}

.itemContainer {
   display: flex;
   flex-direction: column;
   max-height: 180px; /* adjust as needed */
   flex-basis: 47%;
   overflow: hidden;
   transition: max-height 0.4s ease-in-out;
   min-width: 500px;
   background-color: #0e0953;
   border: solid 2px #000000;
   padding: 10px;
   font-size: 20px;
   color: white;
   font-weight: 600;
}

.expanded {
   max-height: fit-content; /* adjust as needed */
}

.name {
   font-size: 20px;
   font-weight: bold;
}

.category {
   margin-bottom: 10px;
}

.cost {
   margin-bottom: 10px;
}

.description {
   margin-bottom: 10px;
}

.reviews_url {
   max-width: fit-content;
   margin-bottom: 10px;
   margin-top: 20px;
   color: white;
}

.instructions_url {
   max-width: fit-content;
   margin-bottom: 10px;
   margin-top: 20px;
   color: white;
}

.advantages {
   margin-bottom: 10px;
}

.disadvantages {
   margin-bottom: 10px;
}

.manufacturer {
   margin-bottom: 10px;
}

.manufacturer_url {
   max-width: fit-content;
   margin-bottom: 10px;
   margin-top: 20px;
   color: white;
}

.vendors {
   margin-bottom: 10px;
}

.resources {
   margin-bottom: 10px;
}

.vetted_by_bvi_users {
   margin-bottom: 10px;
}

.dissertations {
   margin-bottom: 10px;
}

.footer {
   background-color: #2c2f32;
   color: #ffffff;
   text-align: center;
   padding: 0px;
   position: fixed;
   bottom: 0px;
   width: 100%;
   height: 40px;
}

@media (max-width: 1078px) {
   .itemContainer {
      flex-basis: 100%;
      min-width: none;
   }
}