/* styles.css */

.header {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   gap: 14px;
   background-color: #0b0d10;
   color: #ffffff;
   border-bottom: solid 3px #000000;
   text-align: center;
   position: fixed;
   width: 100%;
 }

 .h1 {
   margin: 0;
   font-size: 30px;
   font-weight: bold;
   padding: 20px;
   padding-left: 40px;
   font-size: 38px;  
 }
 
.nav {
   display: flex;
   gap: 10px;
   align-items: center;
   padding: 20px;
   padding-right: 40px;
   flex-wrap: wrap;
}

.header_p {
   text-decoration: none;
   font-size: large;
   font-weight: bold;
}

.nav_button {
   color: #ffffff;
   text-decoration: none;
   padding: 10px;
   border-bottom-left-radius: 20px;
   border-top-right-radius: 20px;
   background-color: #37628d;
   font-size: large;
   font-weight: bold;
}

   .nav_button:hover {
      background-color: #0cc430;
   }

.search {
   display: flex;
   font-size: 30px;
   max-height: 32px;
   margin: 20px;
   margin-left: auto;
   
}

.main {
   padding: 20px;
}

.inventoryContainer {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 28px;
   padding: 95px 10px;
}

.itemHeader {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   background-color: #37628d;
   color: #ffffff;
   padding: 6px;
   border: solid 2px #000000;

}

.clickMe {
   max-height: 40px;
   margin-right: 10px;
   color: rgb(255, 255, 255);
   text-align: right;
   right: 0px;
   font-weight: 600;
   font-size: 16px;
}

.itemContainer {
   display: flex;
   flex-direction: column;
   max-height: 180px; /* adjust as needed */
   flex-basis: 45%;
   overflow: hidden;
   transition: max-height 0.4s ease-in-out;
   min-width: 500px;
   background-color: #0f0f12;
   border: solid 2px #000000;
   padding: 1.5%;
   font-size: 20px;
   color: #ffffff;
   cursor: pointer;
}

.expanded {
   max-height: fit-content; /* adjust as needed */
}

.name {
   font-size: 24px;
   font-weight: bold;
   padding-left: 12px;
   margin: 14px 0px;
}

.category {
   margin-bottom: 10px;
}

.cost {
   margin-bottom: 10px;
}

.desc {
   margin-bottom: 10px;
   font-weight: bold;
}

.reviews_url {
   max-width: fit-content;
   margin-bottom: 10px;
   margin-top: 20px;
   color: rgb(67, 127, 255);
}

.reviews_url:visited {
   color: #a186ff;
   font-size: 20px;
   font-weight: bold;
}

.instructions_url {
   max-width: fit-content;
   margin-bottom: 10px;
   margin-top: 20px;
   color: rgb(67, 127, 255);
}

.instructions_url:visited {
   color: #a186ff;
   font-size: 20px;
   font-weight: bold;
}

.advantages {
   margin-bottom: 10px;
}

.disadvantages {
   margin-bottom: 10px;
}

.manufacturer {
   margin-bottom: 10px;
}

.manufacturer_url {
   max-width: fit-content;
   margin-bottom: 10px;
   margin-top: 20px;
   color: rgb(67, 127, 255);
}

.manufacturer_url:visited {
   color: #a186ff;
   font-size: 20px;
   font-weight: bold;
}

.vendors {
   margin-bottom: 10px;
}

.resources {
   margin-bottom: 10px;
}

.vetted_by_bvi_users {
   margin-bottom: 10px;
}

.dissertations {
   margin-bottom: 10px;
}

.footer {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #2c2f32;
   color: #ffffff;
   text-align: center;
   padding: 0px;
   position: fixed;
   bottom: 0px;
   width: 100%;
   height: 40px;
}

@media (max-width: 1175px) {
   .itemContainer {
      flex-basis: 80%;
      min-width: none;
   }
}


@media (max-width: 1075px) {
   .itemContainer {
      flex-basis: 90%;
      min-width: none;
   }
   .header {
      flex-direction: column;
      height: auto;
   }
   .nav {
      padding: 0px;
      padding-bottom: 20px;
   }
   .search {
      margin: 0px;
   
   }
   .inventoryContainer {
      margin-top: 140px;
   }
}

@media (max-width: 560px) {
   .header {
      position: relative;
      margin-bottom: 20px;
   }
   .h1 {
      font-size: 30px;
   }
   .header_p {
      font-size: medium;
   }
   .nav_button {
      padding: 5px;
      font-size: medium;
   }
   .search {
      font-size: 20px;
   }
   .inventoryContainer {
      margin-top: 0px;
      padding-top: 0px;

   }

   .itemContainer {
      min-width: 300px;
      flex-basis: 97%;
      min-width: none;
   }
   .clickMe {
      font-size: 14px;
      text-wrap: wrap;
      margin-left: 10px;
   }
   .name {
      font-size: 20px;
   }
   .itemHeader {
      font-size: 16px;
   }
   .itemContainer {
      font-size: 16px;
   }
   .footer {
      height: 60px;
   }
}

@media (max-width: 340px) {
   .title {
      font-size: 30px;
   }
   .subtitle {
      font-size: 20px;
   }
   .nav_button {
      padding: 5px;
      font-size: medium;
   }
   .info {
      font-size: medium;
   }
}