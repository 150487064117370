.body {
   font-family: Arial, sans-serif;
   margin: 0;
   padding: 0;
   background-color: #991616;
}

.header {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   gap: 0px;
   background-color: #0b0d10;
   color: #ffffff;
   border-bottom: solid 3px #000000;
   text-align: center;
   position: fixed;
   width: 100%;
   box-sizing: border-box;
   height: 90px;
 }

 .main {
   padding: 20px;
   padding-top: 90px;
   background-color: #d6c2c2;
   background-image: url('../public/polygon_background.png');
   background-size:cover;
   height: calc(100vh - 40px - 90px);
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;

}

 .title {
   margin: 0;
   font-size: 30px;
   font-weight: bold;
   padding: 20px;
   padding-left: 40px;
   font-size: 38px;  
 }

 .subtitle {
   margin: 0;
   margin-right: auto;
   margin-top: 20px;
   font-size: 30px;
   font-weight: bold;
   padding: 0px 0px 0px 0px;
   font-size: 22px;  
   font-style: italic;
 }

 
.nav {
   display: flex;
   gap: 10px;
   justify-content: center;
   align-items: center;
   padding: 20px;
   padding-right: 40px;
   flex-wrap: wrap;
}

.header_p {
   text-decoration: none;
   font-size: large;
   font-weight: bold;
}

.nav_button {
   color: #ffffff;
   text-decoration: none;
   padding: 10px;
   border-bottom-left-radius: 20px;
   border-top-right-radius: 20px;
   background-color: #37628d;
   font-size: large;
   font-weight: bold;
}

   .nav_button:hover {
      background-color: #0cc430;
   }

   .info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(236, 236, 223);
      padding: 20px;
      border: solid 2px #000000;
      border-radius: 10px;
      font-size: large;
      max-width: 500px;
    }

    .attr {
      font-size: medium;
    }

   .p {
      margin: 0;
      padding: 10px;
      font-size: 22px;
      font-weight: bold;
    }

   .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2c2f32;
      color: #ffffff;
      text-align: center;
      padding: 0px;
      position: fixed;
      bottom: 0px;
      width: 100%;
      height: 40px;
   }

   @media (max-width: 1075px) {
      .header {
         flex-direction: column;
         height: auto;
      }
      .subtitle {
         margin: 10px;
      }
      .nav {
         padding: 20px;
      }
   }

   @media (max-width: 450px) {
      .subtitle {
         margin: 0;
      }
   }

   @media (max-width: 340px) {
      .title {
         font-size: 30px;
      }
      .subtitle {
         font-size: 20px;
      }
      .nav_button {
         padding: 5px;
         font-size: medium;
      }
      .info {
         font-size: medium;
      }
   }